import React from 'react';
import { Route } from 'react-router-dom';
import { ExplorePage } from '@backstage/plugin-explore';
import { ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  techdocsPlugin,
  TechDocsReaderPage,
  TechDocsIndexPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { as24ChatPage } from './components/as24-chat/AS24ChatPage';
import { Root } from './components/Root';
import { SignInPage } from './components/SignInPage/SignInPage';
import { oktaAuthApiRef } from '@backstage/core-plugin-api';
import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PhoneIcon from '@material-ui/icons/Phone';
import SlackIcon from './icons/SlackIcon'
import {
  createTheme,
  darkTheme,
  genPageTheme,
  lightTheme,
  shapes,
} from '@backstage/theme';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { VisitListener } from '@backstage/plugin-home';
import { isProduction } from './utils';
import { renderNode } from './components/catalog/renderer/KindAndTypeSelectionIcon';
import { groupsPage } from './components/groups/GroupsPage'
import { HomePage } from './components/home/HomePage';
import { DocsNavAddon } from './plugin';
import { CustomTechDocsHome } from './components/techdocs/CustomTechDocsHome'
import { CustomCatalogPage } from './components/catalog/CatalogIndexPage';


const as24LightTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#3598DB',
    },
    secondary: {
      main: '#00D0B2',
    },
    error: {
      main: '#E74B3C',
    },
    warning: {
      main: '#E67E23',
    },
    info: {
      main: '#3598DB',
    },
    success: {
      main: '#00D0B2',
    },
    banner: {
      info: '#3598DB',
      error: '#E74B3C',
      text: '#343b58',
      link: '#565a6e',
    },
    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',

  },
  defaultPageTheme: 'home',
  fontFamily: 'Sans-Serif',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#3598DB', '#00D0B2'], shape: shapes.wave2 }),
    documentation: genPageTheme({ colors: ['#E74B3C', '#FFC107'], shape: shapes.wave }),
    tool: genPageTheme({ colors: ['#E67E23', '#FF5722'], shape: shapes.wave }),
    service: genPageTheme({ colors: ['#565a6e', '#9C27B0'], shape: shapes.wave }),
    website: genPageTheme({ colors: ['#00D0B2', '#F44336'], shape: shapes.wave }),
    library: genPageTheme({ colors: ['#E74B3C', '#8BC34A'], shape: shapes.wave }),
    other: genPageTheme({ colors: ['#E67E23', '#FF9800'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#3598DB', '#FFEB3B'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#66aa8e', '#03A9F4'], shape: shapes.wave }),
  },
});

const as24DarkTheme = createTheme({
  palette: {
    ...darkTheme.palette,
    primary: {
      main: '#D6CF00',
    },
    secondary: {
      main: '#00D0B2',
    },
    error: {
      main: '#E74B3C',
    },
    warning: {
      main: '#E67E23',
    },
    info: {
      main: '#3598DB',
    },
    success: {
      main: '#00D0B2',
    },
    banner: {
      info: '#3598DB',
      error: '#E74B3C',
      text: '#343b58',
      link: '#565a6e',
    },
    errorBackground: '#8c4351',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',

  },
  defaultPageTheme: 'home',
  fontFamily: 'Sans-Serif',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#A5A200', '#00DFFF'], shape: shapes.wave2 }),
    documentation: genPageTheme({ colors: ['#E74B3C', '#FFC107'], shape: shapes.wave }),
    tool: genPageTheme({ colors: ['#E67E23', '#FF5722'], shape: shapes.wave }),
    service: genPageTheme({ colors: ['#9C27B0', '#565a6e'], shape: shapes.wave }),
    website: genPageTheme({ colors: ['#00D0B2', '#F44336'], shape: shapes.wave }),
    library: genPageTheme({ colors: ['#E74B3C', '#8BC34A'], shape: shapes.wave }),
    other: genPageTheme({ colors: ['#E67E23', '#FF9800'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#8598DB', '#FFEB3B'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#03A9F4', '#8558aB'], shape: shapes.wave }),
  },
});


function SignInComponent(props: any) {
  return (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'okta-auth-provider',
          title: 'Okta',
          message: 'Sign in using Okta',
          apiRef: oktaAuthApiRef,
        }}
      />
  );
}

const app = createApp({
  icons: {
    phone: PhoneIcon,
    slack: SlackIcon,
  },
  themes: [
    {
      id: 'as24-light',
      title: 'Light',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={as24LightTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
    {
      id: 'as24-dark',
      title: 'Dark',
      variant: 'dark',
      Provider: ({ children }) => (
        <ThemeProvider theme={as24DarkTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
  apis,
  ...(isProduction() && {
    components: {
      SignInPage: SignInComponent,
    }
  }),
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const graphPluginConfig = {
  initialState: {
    selectedKinds: [
      'api',
      'component',
      'system',
      'resource'
    ],
    maxDepth: 2
  },
  renderNode: renderNode
};

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage/>
    </Route>;
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CustomCatalogPage />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/explore" element={<ExplorePage />} />
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <CustomTechDocsHome
      />
    </Route>    
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <DocsNavAddon />
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage {...graphPluginConfig} />} />
    <Route path="/as24-chat" element={as24ChatPage} />
    <Route path="/groups" element={groupsPage} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    {isProduction() && <OAuthRequestDialog />}
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
